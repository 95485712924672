@import 'themes';

@keyframes spin {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  transition: background-color 0.25s ease-out;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  @include themed() {
    background-color: t('background');
  }
}

.sidebar {
  transition: background-color 0.25s ease-out;
  width: 60px;
  min-width: 60px;
  height: 100vh;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include themed() {
    background-color: t('background2');
  }
}

.profileButton {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 60px;
  width: 36px;
  height: 36px;
  border-radius: 100%;

  &:hover {
    filter: opacity(0.75);
  }
}

.profilePic {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: solid 2px white;
  margin-bottom: 10px;
}

.backgroundBlur {
  animation: fadeInOpacity 0.25s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
  background-color: #01223770;
}

.profile {
  height: 430px;
  width: 520px;
  border-radius: 100px;
  border: solid 4px;
  @include themed() {
    background-color: t('background2');
    border-color: t('border');
  }
}

.profileBanner {
  position: relative;
  height: 260px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px 100px 0 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-decoration: none;
}

.profileBannerOverlay {
  position: absolute;
  width: 520px;
  height: 200px;
  bottom: 0;
  background-image: linear-gradient(transparent, rgba(20, 23, 26, .55));
}

.profileBannerContent {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileStrongText {
  color: white;
  text-align: center;
  text-shadow: 0 1px 1px rgba(20, 23, 26, .8);
  margin-bottom: 5px;
}

.profileFollowingCount {
  text-align: center;
  font-size: 36px;
  font-weight: 200;
  @include themed {
    color: t('fontPrimary');
  }
}

.columns {
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  min-width: 1200px;
}

.scrollView {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  scrollbar-base-color: black;
}

/* fontAwesomeIcons */
.Twitter {
  color: #2f95dc;
  position: relative;
  font-size: 1.5em;
}

.sync {
  cursor: pointer;
  color: #2f95dc;
  position: relative;
  font-size: 1.5em;
  margin-top: 50px;
  &:hover {
    color: #91DCF5;
    animation: spin 5s linear infinite;
  }
}

.cog {
  cursor: pointer;
  &:hover {
    color: #91DCF5;
  }
}

.active {
  animation: spin 5s linear infinite;
}

.Suspended {
  display: flex;
  transition: border 0.25s ease-out;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  text-align: center;
  border-width: 0 3px 0 4px;
  @include themed() {
    border: solid t('border');
  }
}

.Deleted {
  transition: border 0.25s ease-out;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  text-align: center;
  border-width: 0 4px 0 3px;
  @include themed() {
    border: solid t('border');
  }
}

.emptyImage {
  padding: 7% 0 2% 0;
  width: 25%;
}

.emptyText {
  color: #c5c5c5;
  font-size: 4em;
}

.menu {
  z-index: 1;
  transition: background-color 0.25s ease-out, border 0.25s ease-out;
  position: absolute;
  border-radius: 0 12px 12px 0;
  width: 8em;
  height: auto;
  left: 45px;
  bottom: -19px;
  display: flex;
  flex-direction: column;
  @include themed() {
    background-color: t('background2');
    border: 4px solid t('border');
  }
}

.settings {
  color: #2f95dc;
  font-size: 1.5em;
  position: relative;
  bottom: 20px;
}

.theme {
  transition: color 0.25s ease-out;
  padding: 15px;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include themed() {
    color: t('fontPrimary');
  }
}

label {
  font-weight: 400;
  min-height: 18px;
  margin: 4px 0;
  width: 4em;
  display: flex;
}

input {
  margin-right: 5px;
}

.checkbox {
  font-weight: 400;
  flex-direction: column;
  margin-top: 6px;
}

.logout {
  transition: background-color 0.25s ease-out, border 0.25s ease-out, color 0.25s ease-out;
  padding: 15px;
  font-weight: 700;
  border-radius: 0 0 12px 0;
  font-size: 16px;
  border-width: 2px 0 0 0;
  outline: none;
  @include themed() {
    border-color: t('border');
    color: t('fontPrimary');
    background-color: t('background2');
  }
}

.logout:hover {
  color: #2f95dc;
}


.header {
  width: 100%;
  transition: border 0.25s ease-out, color 0.25s ease-out;
  border: solid;
  border-width: 0 0 3px 0;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  @include themed() {
    color: t('fontPrimary');
    border-color: t('border');
  }
}


/* profile card */

.card {
  transition: all 0.25s ease-out;
  display: flex;
  height: 161px;
  border: solid #e1e8ed;
  border-width: 0 0 3px 0;
  justify-content: center;
  align-items: center;
  @include themed() {
    border-color: t('border');
    color: t('fontPrimary');
    background-color: t('background2');
  }
}

.cardProfilePicture {
  transition: border-color 0.25s ease-out;
  flex-direction: column;
  height: 100px;
  width: 100px;
  border: solid 2px;
  border-radius: 50%;
  margin: 0 5%;
  @include themed {
    border-color: t('fontPrimary');
  }
}

.textDetail {
  flex: 1;
}

.userId {
  font-size: 36px;
  font-weight: 300;
  padding-bottom: 4px;
}

.dateBanned {
  font-size: 14px;
}

.susPic {
  flex-direction: column;
  height: 100px;
  width: 100px;
  margin: 0 3%;
}


.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginButton {
  cursor: pointer;
  transition: all 0.25s;
  height: 300px;
  width: 300px;
  border-radius: 400px;
  border: 4px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  flex-direction: column;
  color: white;

  &:hover {
    font-size: 2.8em;
    height: 320px;
    width: 320px;
  }

  &:active {
    height: 290px;
    width: 290px;
    font-size: 2.4em;
  }
}

.loader {
  height: 100vh;
  display: flex;
  align-items: center;
}

.NotFound {
  background-color: #ffedef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
  color: #eeeeee;
}

img.NotFound {
  flex: 1;
  height: 50%;
  width: auto;
}

/* Refresh  */

.refresh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  height: 430px;
  width: 520px;
  border-radius: 100px;
  border: solid 4px;
  @include themed() {
    color: t('fontPrimary');
    background-color: t('background2');
    border-color: t('border');
  }
}

.refreshText {
  margin-top: 50px;
  font-weight: 300;
}

.icon {
  margin-top: 10px;
  width: 80px;
  height: 80px;

}
